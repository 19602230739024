import React, { useEffect, useState } from 'react';
import { IStoreConfig } from '../../lib/interfaces/config.interfaces';
import { Box } from '@nimbus-ds/components';

interface ChronometerModelsProps {
  config: IStoreConfig | null;
}

const ChronometerModels: React.FC<ChronometerModelsProps> = ({
  config,
}: ChronometerModelsProps) => {
  const [showPreview, setShowPreview] = useState(false);
  const [minutes, setMinutes] = useState(config?.time_cart_chronometer || 20);

  setTimeout(function () {
    if (minutes === 0) {
      setMinutes(config?.time_cart_chronometer || 20);
    } else {
      setMinutes(minutes - 1);
    }
  }, 60000);

  useEffect(() => {
    const link = document.createElement('link');
    link.href =
      'https://cdn.huapps.com.br/frontstore-apps/nuvemshop/stock-reserve/app.min.css';
    link.rel = 'stylesheet';
    document.head.appendChild(link);
    link.onload = () => {
      setShowPreview(true);
    };

    return () => {
      document.head.removeChild(link);
    };
  }, []);

  useEffect(() => {
    const styleElement = document.createElement('style');
    styleElement.innerHTML = `
      .huapps-cart-chronometer{
        font-family: CentraNube, -apple-system, "system-ui", Roboto, "Helvetica Neue", arial, sans-serif;
      }
      :root {
        --huapps-stock-reserve-primary-color: ${
          config?.css_primary_color || '#000000'
        };
        --huapps-stock-reserve-secondary-color: ${
          config?.css_secondary_color || '#FF0000'
        };
        --huapps-stock-reserve-cta-bg-color: ${
          config?.css_cta_background_color || '#000000'
        };
        --huapps-stock-reserve-cta-text-color: ${
          config?.css_cta_text_color || '#FFFFFF'
        };
        --huapps-stock-reserve-chronometer-bg-color: ${
          config?.css_chronometer_background_color || '#000000'
        };
        --huapps-stock-reserve-chronometer-text-color: ${
          config?.css_chronometer_text_color || '#FFFFFF'
        };
        --huapps-stock-reserve-popup-background-color: ${
          config?.css_popup_background_color || '#FFFFFF'
        };
        --huapps-stock-reserve-popup-title-color: ${
          config?.css_popup_title_color || '#000000'
        };
        --huapps-stock-reserve-popup-text-color: ${
          config?.css_popup_text_color || '#000000'
        };
      }
    `;
    document.head.appendChild(styleElement);
  }, [config]);

  if (!showPreview) {
    return <></>;
  }
  if (config) {
    return (
      <Box maxWidth="100%">
        <div
          className={`huapps-cart-chronometer ${config.chronometer_type}`}
          id="huapps-cart-chronometer"
        >
          <div className="huapps-cart-chronometer-content">
            {config.chronometer_icon &&
            config.chronometer_icon.indexOf('http') === 0 ? (
              <div className="huapps-cart-chronometer-icon">
                <img
                  src={config.chronometer_icon}
                  alt="icon"
                  className="img-fluid img-responsive"
                />
              </div>
            ) : (
              <div
                className="huapps-cart-chronometer-icon"
                dangerouslySetInnerHTML={{ __html: config.chronometer_icon }}
              />
            )}
            <div className="huapps-cart-chronometer-time">
              <div className="js-huapps-cart-chronometer-timer huapps-cart-chronometer-time-value">
                {minutes}
              </div>
              <div className="huapps-cart-chronometer-time-label">minutos</div>
            </div>
            <div className="huapps-cart-chronometer-message">
              <div className="huapps-cart-chronometer-message-center">
                <h4 className="huapps-cart-chronometer-message-title">
                  {config.chronometer_title}
                </h4>
                <p className="huapps-cart-chronometer-message-text">
                  {config.chronometer_text}
                </p>
              </div>
            </div>
            <div className="huapps-cart-chronometer-cta">
              {config.chronometer_checkout_button !== '' ? (
                <button className="huapps-cart-chronometer-cta-action">
                  {config.chronometer_checkout_button}
                </button>
              ) : (
                <></>
              )}
            </div>
          </div>
        </div>
      </Box>
    );
  } else {
    return <></>;
  }
};

export default ChronometerModels;
