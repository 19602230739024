import React, { ChangeEvent, useEffect, useState } from 'react';

import nexo from '../../nexoClient';

import { Page, Layout, HelpLink, FormField } from '@nimbus-ds/patterns';
import {
  Box,
  Button,
  Icon,
  Text,
  Link,
  Input,
  Alert,
} from '@nimbus-ds/components';
import { navigateHeader } from '@tiendanube/nexo/helpers';
import { useTranslation } from 'react-i18next';
import { ExternalLinkIcon, LockIcon } from '@nimbus-ds/icons';
import apiMDInstance from '../../utils/apiMiddlewareUtils';
import { IBillingStoreInfo } from '../../lib/interfaces/billing.interface';

const BillingPage: React.FC = () => {
  const { t } = useTranslation();
  const [storeInfo, setStoreInfo] = useState<IBillingStoreInfo>();
  const [loading, setLoading] = useState<boolean>(true);
  const [couponCode, setCouponCode] = useState<string>('');

  useEffect(() => {
    navigateHeader(nexo, { goTo: '/', text: `${t('general.back')}` });

    apiMDInstance('/billing/check-payment')
      .then(({ data, status }) => {
        if (status === 200 && data) {
          setStoreInfo(data);
        }
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  }, []);

  const startCheckout = async () => {
    try {
      let coupon = '';
      if (couponCode !== '') {
        coupon = `?coupon=${couponCode}`;
      }
      const res = await apiMDInstance.post(
        `/billing/start-checkout${coupon}`,
        {},
      );

      if (window.parent) {
        window.parent.location.href = res.data.url;
      } else {
        console.error('Não foi possível redirecionar para o site externo.');
      }
    } catch (error) {
      console.log(error);
    }
  };

  const customerDashboard = async () => {
    try {
      const res = await apiMDInstance.post('/billing/customer-dashboard', {});

      if (window.parent) {
        window.parent.location.href = res.data.url;
      } else {
        console.error('Não foi possível redirecionar para o site externo.');
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <Page maxWidth="800px">
        <Page.Header title={t('BillingPage.title')} alignItems="center" />
        <Page.Body>
          <Layout columns="1">
            <Layout.Section>
              <Box display="grid" gap="2">
                <Text textAlign="center" fontSize="highlight">
                  {t('BillingPage.description')}
                </Text>
                <Text textAlign="center" fontSize="highlight">
                  {t('BillingPage.description2')}
                </Text>
                <Box marginY="6">
                  <Box display="flex" justifyContent="center">
                    {loading ? (
                      <Button.Skeleton width="200px" />
                    ) : storeInfo === undefined ? (
                      <Alert appearance="danger" title="Erro assinatura">
                        Ocorreu um erro ao consultar dados da assinautra, tente
                        novamente.
                      </Alert>
                    ) : storeInfo.stripe_subscription_id &&
                      storeInfo.billing_status !== 'canceled' &&
                      storeInfo.app_status ? (
                      <Box display="grid" gap="4">
                        <Button appearance="neutral" as="a" href="/">
                          {t('BillingPage.cta_go_home')}
                        </Button>
                        <Button
                          appearance="primary"
                          onClick={customerDashboard}
                        >
                          <Box as="span" display="flex" gap="2">
                            <Icon color="currentColor" source={<LockIcon />} />
                            {t('BillingPage.cta_billing_panel')}
                          </Box>
                        </Button>
                      </Box>
                    ) : (
                      <Box display="grid" gap="4">
                        <FormField label={`${t('BillingPage.coupon_label')}`}>
                          <Input
                            type="string"
                            id="coupon"
                            name="coupon"
                            onChange={(
                              event: ChangeEvent<HTMLInputElement>,
                            ) => {
                              const { value } = event.target;
                              setCouponCode(value);
                            }}
                            value={couponCode}
                          />
                        </FormField>
                        <Button appearance="primary" onClick={startCheckout}>
                          <Box as="span" display="flex" gap="2">
                            <Icon color="currentColor" source={<LockIcon />} />
                            {storeInfo.billing_status === 'none' &&
                            !storeInfo.billing_subscriptions_canceled_ids.length
                              ? t('BillingPage.cta_init_testing')
                              : `Iniciar assinatura`}
                          </Box>
                        </Button>
                      </Box>
                    )}
                  </Box>
                </Box>
                <HelpLink>
                  <Link
                    as="a"
                    href="https://huapps.com.br/ajuda/billing/pt"
                    target="_blank"
                    appearance="primary"
                    textDecoration="none"
                  >
                    {t('BillingPage.helpText')}
                    <Icon source={<ExternalLinkIcon />} color="currentColor" />
                  </Link>
                </HelpLink>
              </Box>
            </Layout.Section>
          </Layout>
        </Page.Body>
      </Page>
    </>
  );
};

export default BillingPage;
