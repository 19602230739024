import React, { useEffect, useState } from 'react';

import {
  Alert,
  Box,
  Button,
  Card,
  Icon,
  Input,
  Label,
  Modal,
  Pagination,
  Link,
  Tabs,
  Text,
  Thumbnail,
  Title,
  Tooltip,
} from '@nimbus-ds/components';
import {
  DataList,
  EmptyMessage,
  Layout,
  Page,
  SideModal,
} from '@nimbus-ds/patterns';
import { goTo, navigateHeaderRemove } from '@tiendanube/nexo/helpers';

import nexo from '../../nexoClient';
import apiIntance from '../../utils/apiUtils';
import { useTranslation } from 'react-i18next';
import { AppStatusBar, BillingButton, ConfigButton } from '../../components';
import { IDashboardTotals } from '../../lib/interfaces/general.interfaces';
import {
  CalendarIcon,
  ChevronDownIcon,
  ExclamationCircleIcon,
  SearchIcon,
  SlidersIcon,
} from '@nimbus-ds/icons';
import {
  formatDate,
  formatMoney,
  formatSimpleDate,
} from '../../utils/formatUtils';

function PageMain() {
  const { t } = useTranslation();

  const [loadingData, setLoadingData] = useState(true);
  const [openFilterSideModal, setOpenFilterSideModal] = useState(false);
  const [openModalDetails, setOpenModalDetails] = useState(false);
  const [modalDetailCart, setModalDetailCart] = useState<any>(null);
  const [totalsData, setTotalsData] = useState<IDashboardTotals | null>(null);
  const [cartDataListActives, setCartDataListActives] = useState<any[] | null>(
    null,
  );
  const [cartDataListFinisheds, setCartDataListFinisheds] = useState<
    any[] | null
  >(null);
  const [cartDataListExpireds, setCartDataListExpireds] = useState<
    any[] | null
  >(null);
  const [loadingCartDataList, setLoadingCartDataList] = useState(true);

  const [filterStartDate, setFilterStartDate] = useState<string>(
    new Date().toISOString(),
  );
  const [filterEndDate, setFilterEndDate] = useState<string>(
    new Date().toISOString(),
  );

  useEffect(() => {
    navigateHeaderRemove(nexo);
    getTotals();
    getCartsList('active');
    getCartsList('finalized');
    getCartsList('expired');
    setLoadingData(false);
  }, []);

  const prepareParamsDate = (onlyParams = false): string => {
    const params: string[] = [];

    if (filterStartDate) {
      params.push(`dt_inicial=${formatSimpleDate(filterStartDate)}`);
    }
    if (filterEndDate) {
      params.push(`dt_final=${formatSimpleDate(filterEndDate)}`);
    }
    if (params.length) {
      return `${!onlyParams ? '?' : '&'}${params.join('&')}`;
    } else {
      return '';
    }
  };

  const getTotals = async () => {
    setLoadingData(true);
    try {
      const result = await apiIntance.get(`/cart/totals${prepareParamsDate()}`);
      setTotalsData(result.data as IDashboardTotals);
    } catch (error) {
      console.log(error);
    }
    setLoadingData(false);
  };

  const getProductName = (product: any): string => {
    const resultName = '';
    try {
      if (product) {
        if (
          typeof product.default_lang === 'string' &&
          typeof product.name === 'object'
        ) {
          if (product.default_lang in product.name) {
            return `${product.name[product.default_lang]}`;
          }
        }
      }
    } catch (error) {
      console.log(error);
    }
    return resultName;
  };

  const getCartsList = async (
    type: 'active' | 'finalized' | 'expired',
    page = 1,
  ) => {
    setLoadingCartDataList(true);
    try {
      const result = await apiIntance.get(
        `/cart/${type}?page=${page}${prepareParamsDate(true)}`,
      );

      if (type === 'active') {
        setCartDataListActives(result.data);
      } else if (type === 'finalized') {
        setCartDataListFinisheds(result.data);
      } else if (type === 'expired') {
        setCartDataListExpireds(result.data);
      }
    } catch (error) {
      console.log(error);
    }
    setLoadingCartDataList(false);
  };

  const handleShowCartDetail = (cart: any) => {
    setOpenModalDetails(true);
    setModalDetailCart(cart);
  };

  const applyFilterDates = () => {
    setLoadingData(true);
    setOpenFilterSideModal(false);
    getTotals();
    getCartsList('active');
    getCartsList('finalized');
    getCartsList('expired');
  };

  const DashboardTotals: React.FC<{ totalsData: IDashboardTotals | null }> = ({
    totalsData,
  }: {
    totalsData: IDashboardTotals | null;
  }) => {
    return (
      <Box
        display="flex"
        justifyContent="space-between"
        gap="6"
        boxSizing="border-box"
        flexWrap={{
          xs: 'wrap',
          md: 'nowrap',
        }}
      >
        <Box
          boxSizing="border-box"
          width={{
            xs: '100%',
            md: '33.3333%',
          }}
        >
          <Card>
            <Card.Body>
              <Box display="grid" gap="2">
                <Title as="h3">
                  <Box as="span" display="flex" alignItems="center" gap="1">
                    {t('MainPage.DashboardTotals.conversion_rate')}
                    <Tooltip
                      position="right"
                      content={t(
                        'MainPage.DashboardTotals.conversion_rate_tooltip',
                      )}
                    >
                      <Icon source={<ExclamationCircleIcon size="small" />} />
                    </Tooltip>
                  </Box>
                </Title>
                {loadingData ? (
                  <Title.Skeleton as="h1" />
                ) : (
                  <Title as="h1">
                    {totalsData?.conversion_rate || '0,00'}%
                  </Title>
                )}
              </Box>
            </Card.Body>
          </Card>
        </Box>
        <Box
          boxSizing="border-box"
          width={{
            xs: '100%',
            md: '33.3333%',
          }}
        >
          <Card>
            <Card.Body>
              <Box display="grid" gap="2">
                <Title as="h3">
                  <Box as="span" display="flex" alignItems="center" gap="1">
                    {t('MainPage.DashboardTotals.converted_carts')}

                    <Tooltip
                      content={t(
                        'MainPage.DashboardTotals.converted_carts_tooltip',
                      )}
                    >
                      <Icon source={<ExclamationCircleIcon size="small" />} />
                    </Tooltip>
                  </Box>
                </Title>
                {loadingData ? (
                  <Title.Skeleton as="h1" />
                ) : (
                  <Title as="h1">{totalsData?.total_finalized || '0'}</Title>
                )}
              </Box>
            </Card.Body>
          </Card>
        </Box>
        <Box
          boxSizing="border-box"
          width={{
            xs: '100%',
            md: '33.3333%',
          }}
        >
          <Card>
            <Card.Body>
              <Box display="grid" gap="2">
                <Title as="h3">
                  <Box as="span" display="flex" alignItems="center" gap="1">
                    {t('MainPage.DashboardTotals.abandoned_carts')}

                    <Tooltip
                      position="left"
                      content={t(
                        'MainPage.DashboardTotals.abandoned_carts_tooltip',
                      )}
                    >
                      <Icon source={<ExclamationCircleIcon size="small" />} />
                    </Tooltip>
                  </Box>
                </Title>
                {loadingData ? (
                  <Title.Skeleton as="h1" />
                ) : (
                  <Title as="h1">{totalsData?.total_expired || '0'}</Title>
                )}
              </Box>
            </Card.Body>
          </Card>
        </Box>
      </Box>
    );
  };

  const CartDataList: React.FC<{
    type: 'active' | 'finalized' | 'expired';
    dataList: any;
  }> = ({
    type,
    dataList,
  }: {
    dataList: any;
    type: 'active' | 'finalized' | 'expired';
  }) => {
    const meta = dataList ? dataList.meta || null : null;
    const list = dataList ? (dataList.result as any[]) || [] : [];

    if (!loadingCartDataList && (list === null || !list.length)) {
      return (
        <Box paddingY="5">
          <EmptyMessage
            title={t('MainPage.tabs.empty_message.title')}
            text={`${t('MainPage.tabs.empty_message.text')}`}
            icon={<SearchIcon size={32} />}
          />
        </Box>
      );
    }

    return (
      <Box display="grid" gap="6">
        <DataList>
          {loadingCartDataList ? (
            <>
              <DataList.Row gap="1">
                <Box display="flex" justifyContent="space-between">
                  <Text.Skeleton />
                  <Text.Skeleton />
                </Box>
                <Link.Skeleton>
                  <Icon color="currentColor" source={<ChevronDownIcon />} />
                </Link.Skeleton>
              </DataList.Row>
              <DataList.Row gap="1">
                <Box display="flex" justifyContent="space-between">
                  <Text.Skeleton />
                  <Text.Skeleton />
                </Box>
                <Link.Skeleton>
                  <Icon color="currentColor" source={<ChevronDownIcon />} />
                </Link.Skeleton>
              </DataList.Row>
            </>
          ) : (
            list.map((item, i) => (
              <DataList.Row key={i} gap="1">
                <Box display="flex" justifyContent="space-between">
                  <Text fontWeight="medium" color="primary-interactive">
                    {type === 'finalized' ? (
                      <Link
                        as="button"
                        onClick={() => goTo(nexo, `/orders/${item._id}`)}
                        appearance="primary"
                      >
                        #{item._id}
                      </Link>
                    ) : (
                      <>#{item._id}</>
                    )}
                  </Text>
                  <Text>{formatDate(item.updatedAt)}</Text>
                </Box>
                <Link
                  textDecoration="none"
                  onClick={() => {
                    handleShowCartDetail(item);
                  }}
                >
                  {item.products.length} {t('general.products')}
                  <Icon color="currentColor" source={<ChevronDownIcon />} />
                </Link>
              </DataList.Row>
            ))
          )}
        </DataList>
        {meta ? (
          <Pagination
            activePage={meta.page}
            onPageChange={(page) => {
              getCartsList(type, page);
            }}
            pageCount={meta.pages}
          />
        ) : (
          <></>
        )}
      </Box>
    );
  };

  return (
    <>
      <Page>
        <Page.Header
          title={t('MainPage.title')}
          buttonStack={
            <>
              <BillingButton />
              <ConfigButton />
            </>
          }
        />
        <Page.Body>
          <AppStatusBar />
          <Layout columns="1">
            <Layout.Section>
              <Box display="grid" gap="8">
                <Box display="flex" flexDirection="column" gap="2">
                  <Box
                    display="flex"
                    gap="1"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Title as="h3">
                      <Box display="flex" gap="1">
                        <Box as="span">Périodo: </Box>
                        <Box as="b">
                          {formatSimpleDate(filterStartDate, 'dd/mm/yyyy')}
                        </Box>
                        <Box as="span">-</Box>
                        <Box as="b">
                          {formatSimpleDate(filterEndDate, 'dd/mm/yyyy')}
                        </Box>
                      </Box>
                    </Title>
                    <Button
                      onClick={() => {
                        setOpenFilterSideModal(true);
                      }}
                    >
                      <Icon color="currentColor" source={<SlidersIcon />} />
                    </Button>
                  </Box>
                </Box>

                <DashboardTotals totalsData={totalsData} />

                <Box display="grid" gap="2">
                  <Title as="h2">{t('MainPage.tabs.title')}</Title>
                  <Tabs preSelectedTab={0}>
                    <Tabs.Item label={t('MainPage.tabs.cart_active.title')}>
                      <CartDataList
                        type="active"
                        dataList={cartDataListActives}
                      />
                    </Tabs.Item>
                    <Tabs.Item label={t('MainPage.tabs.cart_finished.title')}>
                      <CartDataList
                        type="finalized"
                        dataList={cartDataListFinisheds}
                      />
                    </Tabs.Item>
                    <Tabs.Item label={t('MainPage.tabs.cart_expired.title')}>
                      <CartDataList
                        type="expired"
                        dataList={cartDataListExpireds}
                      />
                    </Tabs.Item>
                  </Tabs>
                </Box>
              </Box>
            </Layout.Section>
          </Layout>
        </Page.Body>
      </Page>
      <Modal
        open={openModalDetails}
        onDismiss={() => {
          setOpenModalDetails(false);
        }}
      >
        <Modal.Header
          title={`${t('MainPage.tabs.table.modal.title')} - ${
            modalDetailCart && modalDetailCart._id
              ? `#${modalDetailCart._id}`
              : ''
          }`}
        />
        <Modal.Body padding="none">
          {modalDetailCart && modalDetailCart.products.length ? (
            <DataList>
              {modalDetailCart.products.map((product: any, i: number) => (
                <DataList.Row key={i} paddingX="none">
                  <Box
                    boxSizing="border-box"
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                    gap="2"
                  >
                    <Box
                      boxSizing="border-box"
                      display="flex"
                      justifyContent="space-between"
                      alignItems="center"
                      gap="2"
                    >
                      {product.image && product.image.src ? (
                        <Thumbnail
                          src={product.image.src}
                          alt="my-image"
                          width="30px"
                        />
                      ) : (
                        <Thumbnail alt="my-image" width="30px" />
                      )}
                      <Text fontWeight="medium" color="primary-interactive">
                        {getProductName(product)}
                      </Text>
                    </Box>
                    <Box display="grid">
                      <Text fontSize="caption">
                        {`${product.data.amount}`}un
                      </Text>
                      <Text>{formatMoney(product.price)}</Text>
                    </Box>
                  </Box>
                </DataList.Row>
              ))}
            </DataList>
          ) : (
            <Alert
              appearance="primary"
              title={`${t('MainPage.tabs.table.modal.empty_title')}`}
            >
              {`${t('MainPage.tabs.table.modal.empty_text')}`}
            </Alert>
          )}
        </Modal.Body>
      </Modal>

      <SideModal
        open={openFilterSideModal}
        maxWidth={{ xs: '100%', md: '340px' }}
        title="Filtrar por"
        headerIcon={<Icon color="primary-textHigh" source={<SlidersIcon />} />}
        onRemove={() => setOpenFilterSideModal(false)}
        padding="base"
        paddingHeader="none"
        paddingBody="none"
        paddingFooter="none"
      >
        <Box display="grid" gap="6">
          <Box display="grid" gap="1">
            <Label>Data inicial</Label>
            <Input
              id="start-date"
              type="date"
              max={formatSimpleDate(filterEndDate, 'yyyy-mm-dd')}
              append={<Icon source={<Icon source={<CalendarIcon />} />} />}
              appendPosition="end"
              value={formatSimpleDate(filterStartDate, 'yyyy-mm-dd')}
              onChange={(e) => setFilterStartDate(e.target.value)}
            />
          </Box>
          <Box display="grid" gap="1">
            <Label>Data final</Label>
            <Input
              id="end-date"
              type="date"
              max={formatSimpleDate(new Date(), 'yyyy-mm-dd')}
              append={<Icon source={<Icon source={<CalendarIcon />} />} />}
              appendPosition="end"
              value={formatSimpleDate(filterEndDate, 'yyyy-mm-dd')}
              onChange={(e) => setFilterEndDate(e.target.value)}
            />
          </Box>

          <Button appearance="primary" onClick={applyFilterDates}>
            Aplicar
          </Button>
        </Box>
      </SideModal>
    </>
  );
}

export default PageMain;
